import React, { useEffect, useState } from 'react';
import Producto from '../Components/Producto';
import "../styles/producto.css";
import axios from 'axios';
import Pagination from '../Components/Pagination';
import { useParams } from 'react-router-dom';
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import LoadingIndicator from "../Components/Common/LoadingIndicator";
import { useSearchParams } from 'react-router-dom';
import { getListAnimes } from '../api/items/api';
import { capitalize } from '../libs/capitalize';


const Productos = (props) => {

    const [dataItems, setDataItems] = useState([]);
    const [dataItemsAux, setDataItemsAux] = useState([]);
    const [page, setPage] = useState(0);
    const [itemsxPagina, setItemsxPagina] = useState(0);
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    const [isActive, setIsActive] = useState(false);
    const [isActive2, setIsActive2] = useState(false);

    const [animeList, setAnimeList] = useState([]);

    const { promiseInProgress } = usePromiseTracker();


    const paramsObj = {};
    searchParams.forEach((value, key) => {
        paramsObj[key] = value;
    });

    console.log(paramsObj);


    useEffect(() => {

        let url = `${process.env.REACT_APP_API_URL}/api/productos/producto?anime=${paramsObj.anime ? paramsObj.anime : ""}&tipo=${paramsObj.tipo ? paramsObj.tipo : ""}&stock=true`

        //Por el buscador
        if (props.modo === "buscador") {
            url = `${process.env.REACT_APP_API_URL}/api/productos/search/${params.busqueda}`;
            trackPromise(
                getItems(url).then(items => {
                    setDataItems(items);
                    setDataItemsAux(items);
                    setItemsxPagina(10);
                })
            );
        }
        else{
            trackPromise(
                getItems(url).then(items => {
                    setDataItems(items);
                    setDataItemsAux(items);
                    setItemsxPagina(10);
                })
            );
    
            getListAnimes()
                .then(res => {
                    setAnimeList(res.data);
                })
                .catch(err => {
                    console.log(err);
                });
        }

       

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeOrdenar = (e) => {
        if (e === "newest") {
            setDataItems(dataItemsAux);
        }
        if (e === "older") {
            let arrayReverse = dataItemsAux.slice().reverse();
            console.log(dataItemsAux);
            setDataItems(arrayReverse);
        }

        if (e === "cheaper") {
            let arrayCheaper = dataItemsAux.slice();
            arrayCheaper.sort((a, b) => a.price - b.price)
            setDataItems(arrayCheaper);
        }

        if (e === "expensive") {
            let arrayCheaper = dataItemsAux.slice();
            arrayCheaper.sort((a, b) => b.price - a.price)
            setDataItems(arrayCheaper);
        }
    }

    const handleFilterChange = (key, value) => {
        setSearchParams((prevParams) => {
            if (value === "todos") {
                prevParams.delete(key);
                prevParams.append(key, "");
            }
            else {
                if (searchParams.getAll(key).length > 0) {
                    prevParams.delete(key);
                    prevParams.append(key, value);
                }
                else {
                    prevParams.append(key, value); // <-- append key-value pair
                }
            }
            return prevParams;
        });
    }

    const changePage = (num) => {
        return setPage(num);
    };

    const getItems = (url) => {
        const promise = new Promise((resolve) => {
            resolve(axios.get(url).then(
                response => response.data
            ));
        });
        return promise;
    };

    useEffect(() => {
        if(props.modo !== "buscador"){
            trackPromise(
                getItems(`${process.env.REACT_APP_API_URL}/api/productos/producto?anime=${paramsObj.anime ? paramsObj.anime : ""}&tipo=${paramsObj.tipo ? paramsObj.tipo : ""}&stock=true`).then(items => {
                    setDataItems(items);
                    setDataItemsAux(items);
                    setItemsxPagina(10);
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);


    const handleSelect = () => {
        setIsActive(!isActive);

        setIsActive2(false);

    };

    const handleSelect2 = () => {
        setIsActive2(!isActive2);
        setIsActive(false);

    };

    const optionTipos = [
        "todos",
        "playera",
        "sudadera",
        "llavero",
        "poster",
        "pin",
        "peluche",
        "figura",
        "variado"
    ];


    return (
        <main>
            <div className="containerMain">
                <div className="rutaTitle">
                    <h2>Inicio / {props.rutaProducto} {
                        paramsObj.anime && `/ ${paramsObj.anime}`
                    } {
                            paramsObj.tipo && `/ ${paramsObj.tipo}`
                        } </h2>
                </div>
                <div className="opcionesPaginacion">
                    <form action="" className='formFiltros'>
                        <div className="opcionArticulos">
                            <p>Articulos por Página:</p>
                            <input type="number" min="1" max="30" value={itemsxPagina} onChange={(e) => setItemsxPagina(parseInt(e.target.value))} />
                        </div>
                        <div className="opcionOrdenar">
                            <p>Ordenar</p>
                            <select onChange={(e) => { changeOrdenar(e.target.value); }} defaultValue={'newest'}>
                                <option value="newest">Más reciente</option>
                                <option value="older">Más antiguo</option>
                                <option value="cheaper">Más barato</option>
                                <option value="expensive">Más caro</option>
                            </select>
                        </div>

                        {
                            props.modo !== "buscador" && (
                                <>
                                    <div className="opcionOrdenar">
                                        <p>Tipo</p>

                                        <div className={`custom-select ${isActive ? "active" : ""}`}>
                                            <button className="select-button" onClick={handleSelect} type='button'>
                                                {searchParams.get("tipo") ? capitalize(searchParams.get("tipo")) : "Todos"}
                                                <span class="arrow"></span>
                                            </button>
                                            <div className="selected-value"></div>
                                            <ul className="select-dropdown">
                                                {optionTipos.map((option, index) => (
                                                    <li key={index} onClick={() => { handleFilterChange("tipo", option); setIsActive(false); }} tabIndex="0">
                                                        <label>{capitalize(option)}</label>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>

                                    {
                                        animeList.length > 0 && <div className="opcionOrdenar">
                                            <p>Anime</p>

                                            <div className={`custom-select ${isActive2 ? "active" : ""}`}>
                                                <button className="select-button" onClick={handleSelect2} type='button'>
                                                    {searchParams.get("anime") ? capitalize(searchParams.get("anime")) : "Todos"}
                                                    <span class="arrow"></span>
                                                </button>
                                                <div className="selected-value"></div>
                                                <ul className="select-dropdown">
                                                    <li onClick={() => { handleFilterChange("anime", "todos"); setIsActive2(false); }} tabIndex="0">
                                                        <label>Todos</label>
                                                    </li>
                                                    {animeList.map((option, index) => (
                                                        <li key={index} onClick={() => { handleFilterChange("anime", option.anime); setIsActive2(false); }} tabIndex="0">
                                                            <label>{capitalize(option.anime)}</label>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>

                                    }
                                </>
                            )
                        }



                    </form>
                </div>
                {
                    (promiseInProgress === true) ?
                        <LoadingIndicator />
                        :
                        <>
                            <div className="productosContainer">

                                {
                                    dataItems.length > 0 ?
                                        <>
                                            {
                                                dataItems.slice(page, page + itemsxPagina)
                                                    .map((item) =>
                                                        <div className="containerProducto">
                                                            <Producto producto={item} key={item.idItem} ></Producto>
                                                        </div>
                                                    )
                                            }
                                        </>
                                        :
                                        <>
                                            <p>No hay productos disponibles</p>
                                        </>

                                }
                            </div>
                            <Pagination changePage={changePage} items={dataItems} itemsPerPage={itemsxPagina} />
                        </>
                }

            </div>
        </main>
    )
}

export default Productos
