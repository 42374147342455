import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { getPedidoById } from '../api/pedido/api';
import '../styles/carrito.css';
import ItemPedido from '../Components/ItemPedido';

const Pedido = () => {
    const params = useParams();
    const [dataPedido, setPedido] = useState(null);
    const [subtotal, setSubtotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const cookies = new Cookies();
    const token = cookies.get("jwt_auth");


    const getSubtotal = () => {
        if(dataPedido){
            const calcularSubtotal = dataPedido.productos.reduce((acc, curr) => {
                return acc + (curr.cantidad * curr.producto.price);
            }, 0)
            setSubtotal(calcularSubtotal);
        }
    }

    const fetchData = async () => {
        setIsLoading(true)
        await getPedidoById(params.id, token).then(data => {
            console.log(data.data);
            setPedido(data.data);
        }).catch(err => {
            console.log(err);
        })
        setIsLoading(false)
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getSubtotal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataPedido]);

    if (isLoading || dataPedido === null) {
        return (
            <main>
                <div className="containerMain">
                    <h1>Cargando</h1>
                </div>
            </main>
        )
    }

    return (
        <main>
            {console.log(dataPedido)}
            <div className="containerMain">
                <div className="carritoContainer">
                    <div className="left-body">
                        <h2 className="title-container">Productos</h2>
                        {dataPedido.productos.map(item =>
                            <ItemPedido producto={{ ...item.producto, quantity: item.cantidad, talla: item.talla }} key={"" + item.idItem + item.talla} />
                        )}
                    </div>
                    <div className="right-body">
                        <h2 className="title-container">Resumen del pedido</h2>
                        <p className="folio">Folio: {dataPedido._id}</p>
                        <p className="folio">Pedido por el usuario: {dataPedido.usuario.username}</p>
                        <div className="top-resumen">
                            <div className="item-resumen">
                                <p className="left">Envio</p>
                                <p className="right">$25 MXN</p>
                            </div>
                            <div className="item-resumen">
                                <p className="left">Subtotal</p>
                                <p className="right">${subtotal} MXN</p>
                            </div>
                        </div>
                        <div className="buttom-resumen">
                            <div className="item-resumen">
                                <p className="left">Total</p>
                                <p className="totalPrice">${subtotal + 25} MXN</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </main>
    )
}

export default Pedido