import axios from "axios";

export const getItemById = (id) => {
    const promise = new Promise((resolve) => {
        const url = `${process.env.REACT_APP_API_URL}/api/productos/producto/${id}`;
        resolve(axios.get(url).then(
            response => response
        ).catch(err => {
            console.log(err);
        }));
    });
    return promise;
};

export const getItems = async () => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/api/productos/producto`);
}

export const getListAnimes = async () => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/api/productos/listanimes`)
}