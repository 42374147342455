import React from 'react';
import ReactDOM from 'react-dom/client';
import Header from './Components/Header';
import Footer from './Components/Footer';
import './index.css';
import { ShoppingCartProvider } from './context/ShoppingCartProvider';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { UserProvider } from './context/userContext';
import Rutas from './rutas';
import { Helmet } from 'react-helmet';

if (process.env.NODE_ENV === 'production') disableReactDevTools();

export default function App() {
  return (
    <ShoppingCartProvider>
      <Helmet htmlAttributes={{ lang: "es" }}>
        <title>Arekku Tienda</title>
        <meta name="description" content="Página web de Arekku Tienda" />
        <meta name="keywords" content="anime, arekku, tienda, champoton" />
        <meta name="author" content="Arekku" />
        <meta property="og:title" content="Arekku Tienda" />
        <meta property="og:description" content="Página web de Arekku Tienda" />
      </Helmet>

      <UserProvider>
        <Header />
        <Rutas />
        <Footer />
      </UserProvider>
    </ShoppingCartProvider>

  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);