import React from "react"
import ContentLoader from "react-content-loader"

const LoaderItem = (props) => (
  <ContentLoader 
    speed={2}
    height="100%" width="100%"
    viewBox="0 0 419 521"
    backgroundColor="#dbdbdb"
    foregroundColor="#ff9494"
    {...props}
  >
    <rect x="360" y="506" rx="3" ry="3" width="57" height="11" /> 
    <rect x="2" y="503" rx="3" ry="3" width="52" height="13" /> 
    <rect x="3" y="473" rx="3" ry="3" width="409" height="13" /> 
    <rect x="2" y="439" rx="3" ry="3" width="174" height="13" /> 
    <rect x="3" y="8" rx="20" ry="20" width="414" height="411" />
  </ContentLoader>
)

export default LoaderItem