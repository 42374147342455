/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import Swal from 'sweetalert2';
import { CartContext } from '../context/ShoppingCartProvider';
import '../styles/carrito.css';
import ItemCart from '../Components/ItemCart';
import { crearPedido } from '../api/pedido/api';
import Cookies from 'universal-cookie';
import jwt from "jwt-decode";

const CarritoPage = () => {
    const [cart, setCart] = useContext(CartContext);
    const subtotal = cart.reduce((acc, curr) => {
        return acc + (curr.quantity * curr.price);
    }, 0)
    const cookies = new Cookies();

    const enviarMensaje = () => {

        if (subtotal === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Agregue productos al carrito...',
                text: 'Necesita tener productos en el carrito para enviar mensaje',
            })
        }
        else {
            const dataCarrito = cart.map(item => {
                return {
                    producto: item._id,
                    cantidad: item.quantity,
                    talla: item.talla,
                    costo: item.quantity * item.price
                }
            })


            const token = cookies.get("jwt_auth");
            
            if(token){
                const decoded = jwt(token)

                crearPedido({ costoTotal: subtotal, productos: dataCarrito, usuario: decoded.id }, token)
                    .then(res => {
                        setCart([])
                        console.log(res.data)
                        let message = `Hola, realicé el siguiente pedido: ${process.env.REACT_APP_CLIENT_URL}/pedido/${res.data._id}`;
                        let url = "https://api.whatsapp.com/send?phone=529821845028&text=" + message
                        window.open(url);
                        window.location = `/pedido/${res.data._id}`;
                    })
                    .catch(err => {
                        Swal.fire({
                            title: '¡Upss!',
                            text: "Ocurrió un error al generar el pedido",
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        })
                        console.log(err)
                    })
            }
            else{
                Swal.fire({
                    title: '¡No has iniciado sesión!',
                    text: "Inicia sesión para terminar un pedido",
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
            

        }


    }

    return (
        <main>
            <div className="containerMain">
                <div className="carritoContainer">
                    <div className="left-body">
                        <h2 className="title-container">Productos</h2>
                        {cart.map(item => <ItemCart producto={item} key={"" + item.idItem + item.talla} />)}
                    </div>
                    <div className="right-body">
                        <h2 className="title-container">Resumen del pedido</h2>
                        <div className="top-resumen">
                            <div className="item-resumen">
                                <p className="left">Envio</p>
                                <p className="right">$25 MXN</p>
                            </div>
                            <div className="item-resumen">
                                <p className="left">Subtotal</p>
                                <p className="right">${subtotal} MXN</p>
                            </div>
                        </div>
                        <div className="buttom-resumen">
                            <div className="item-resumen">
                                <p className="left">Total</p>
                                <p className="totalPrice">${subtotal + 25} MXN</p>
                            </div>
                            <button className="btnEnviar" onClick={enviarMensaje}><i className="fa-solid fa-paper-plane"></i> Enviar pedido</button>
                        </div>

                    </div>
                </div>
            </div>
        </main>
    );
}

export default CarritoPage;
