import axios from "axios";

const crearPedido = async (data, token) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/api/pedido/producto`, data, {
        headers: {
            "x-access-token": token
        }
    })
};

const getPedidoById = async (id, token) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/api/pedido/producto/${id}`, {
        headers: {
            "x-access-token": token
        }
    })
};

export {crearPedido, getPedidoById};