import React from 'react';
import { Link } from 'react-router-dom';



const ItemPedido = (props) => {
    return (
        <div className="item-shop">
            <Link to= {`/producto/${props.producto.idItem}`}>
            <img src={props.producto.img} alt="" />
            </Link>
            <div className="info-item">
                <p className="anime">{props.producto.anime}</p>
                <p className="title">{props.producto.title}</p>
                <p className="talla">{props.producto.talla}</p>
                <p className="price">${props.producto.price}</p>
                <div className="opc-item">
                    <p className="amount">Cantidad: {props.producto.quantity}</p>
                </div>

            </div>
        </div>
    );
}

export default ItemPedido;
