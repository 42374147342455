import React from 'react'
import {  BrowserRouter, Route, Routes } from 'react-router-dom'
import ProductoPage from './pages/Producto';
import Productos from './pages/Productos';
import CarritoPage from './pages/CarritoPage';
import Inicio from './pages/Inicio';
import LoginPage from './pages/LoginPage';
import Pedido from './pages/Pedido';


const Rutas = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Inicio />} />
                <Route path='/productos' element={< Productos rutaProducto="Productos" modo="todos" />} />
                <Route path='/buscar/:busqueda' element={< Productos rutaProducto="Busqueda" modo="buscador" />} />
                <Route path="/producto/:id" element={<ProductoPage />} />
                <Route path='/carrito' element={< CarritoPage />} />
                <Route path='/login' element={< LoginPage />} />
                <Route path='/pedido/:id' element={< Pedido />} />
                
            </Routes>
        </BrowserRouter>
    )
}

export default Rutas