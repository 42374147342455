import React from 'react'
import Carousel from 'better-react-carousel'
import { useState } from 'react';
import { useEffect } from 'react';
import { getItems } from '../api/items/api';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import LoaderItem from './LoaderItem';
import ProductoCarrusel from './ProductoCarrusel';


const Gallery = () => {

  const [dataItems, setDataItems] = useState([]);

  const { promiseInProgress } = usePromiseTracker();

  useEffect(() => {
    trackPromise(
      getItems().then(res => {
        setDataItems(res.data);
      }).catch(err => {
        console.log(err);
      })
    )
  }, [])

  return (
    <>
      {
        (promiseInProgress === true) ?
        <Carousel cols={4} rows={1} gap={30} mobileBreakpoint={650}>
          <Carousel.Item>
          <LoaderItem/>
          </Carousel.Item>
          <Carousel.Item>
          <LoaderItem/>
          </Carousel.Item>
          <Carousel.Item>
          <LoaderItem/>
          </Carousel.Item>
          <Carousel.Item>
          <LoaderItem/>
          </Carousel.Item>
          </Carousel>
          :
          <Carousel cols={4} rows={1} gap={30} mobileBreakpoint={650}>
            {
              dataItems.slice(0, 8).map(item =>
                <Carousel.Item key={item._id}>
                  <ProductoCarrusel producto={item} key={item.idItem} ></ProductoCarrusel>
                </Carousel.Item>
              )
            }
          </Carousel>
      }
    </>

  )
}

export default Gallery;
