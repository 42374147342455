
let menuItemslet = [
    {
        title: 'Productos',
        url: '/productos',
    },
    {
        title: 'Por Anime',
        url: '/xanime',
        submenu: [
            {
                slug: "/Chainsaw%20Man",
                anchor: "Hunter x Hunter",
            },
            {
                slug: "/Chainsaw%20Man",
                anchor: "Chainsaw Man",
            },
            {
                slug: "/Demon%20Slayer",
                anchor: "Demon Slayer",
            },
        ],
    },
    {
        title: 'Por tipo',
        url: '/buscar',
        submenu: [
            {
                slug: "/productos?tipo=playera",
                anchor: "Playera",
            },
            {
                slug: "/productos?tipo=sudadera",
                anchor: "Sudadera",
            },
            {
                slug: "/productos?tipo=llavero",
                anchor: "Llavero",
            },
            {
                slug: "/productos?tipo=pin",
                anchor: "Pin",
            },
            {
                slug: "/productos?tipo=poster",
                anchor: "Poster",
            },
            {
                slug: "/productos?tipo=peluche",
                anchor: "Peluche",
            },
            {
                slug: "/productos?tipo=figura",
                anchor: "Figura",
            },
            {
                slug: "/productos?tipo=variado",
                anchor: "Variado",
            },
        ],
    },
];

export const menuItems = menuItemslet;
