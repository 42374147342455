import axios from "axios";

const signUpUser = async (data) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/signUP`, data)
};

const signInUser = async (data) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/signin`, data)
};

export {signUpUser, signInUser};