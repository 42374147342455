import React, { useContext } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { CartContext } from '../context/ShoppingCartProvider';
import '../styles/item.css';
import LoadingIndicator from '../Components/Common/LoadingIndicator';
import { getItemById } from '../api/items/api';

function ProductoPage() {

    const [dataItem, setDataItem] = useState({});
    const [selectedTalla, setSelectedTalla] = useState(null);
    const [amount, setAmount] = useState(0);
    const [subtotal, setSubtotal] = useState(0);
    // eslint-disable-next-line no-unused-vars
    const [cart, setCart] = useContext(CartContext);


    const params = useParams();

    useEffect(() => {
        trackPromise(
        getItemById(params.id).then(data => {
            console.log(data);
            setDataItem(data.data);
            setSelectedTalla(data.data.tallas[0])
        }).catch(err => {
            console.log(err);
        })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const addToCart = () => {

        if (amount > 0) {
            if (amount <= selectedTalla.count) {
                setCart((currItems) => {
                    const isItemFound = currItems
                        .find((item) => item.idItem === dataItem.idItem && item.talla === selectedTalla.name);
                    if (isItemFound) {
                        if ((isItemFound.quantity + amount) <=  selectedTalla.count) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Agregado producto exitosamente',
                                footer: '<a href="/carrito">Ir al carrito</a>'
                            })
                            return currItems.map((item) => {
                                if (item.idItem === dataItem.idItem && item.talla === selectedTalla.name) {
                                    return { ...item, quantity: item.quantity + amount, talla: selectedTalla.name }
                                } else {
                                    return item;
                                }
                            });
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'No se puede agregar...',
                                text: 'Rebasa la cantidad de lo que hay en stock'
                            })
                            return currItems;
                        }
                    } else {
                        Swal.fire({
                            icon: 'success',
                            title: 'Agregado producto exitosamente',
                            footer: '<a href="/carrito">Ir al carrito</a>'
                        })
                        return [...currItems, { ...dataItem, quantity: amount, talla: selectedTalla.name }]
                    }
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'No se puede agregar más cantidad del stock...',
                    text: 'Cambia la cantidad del producto a uno menor de lo que hay'
                })
            }

        }
        else {
            Swal.fire({
                icon: 'error',
                title: 'Agrega más de un producto...',
                text: 'Cambia la cantidad del producto distinto a cero!'
            })
        }
    };

    // const tallaString = (num) => {
    //     let talla = "";
    //     if (num === 0) talla = "CHICA";
    //     if (num === 1) talla = "MEDIANA";
    //     if (num === 2) talla = "GRANDE";
    //     return talla;
    // }

    // const changeTalla = (newTalla) => {
    //     setTalla(newTalla);
    //     setAmount(0);
    //     setSubtotal(0);
    // }
    
    

    return (
        <main>
            <div className="containerMain">
                <LoadingIndicator />
                <div className="datosContainer">
                    <div className="leftContainer">
                        <img src={dataItem.img} alt={dataItem.title} />
                    </div>
                    <div className="rightContainer">
                        <p className="animeItem">{dataItem.anime}</p>
                        <p className="titleItem">{dataItem.title}</p>
                        <p className="priceItem">${dataItem.price} MXN</p>
                        {dataItem.stock ?
                            <>
                                <div className="tallasContainer">
                                    <p>Talla: <span className="bold">{selectedTalla.name}</span></p>
                                    <p className="cantidad">En stock: {selectedTalla.count}</p>
                                    <div className="tallasButtons">
                                        {
                                            dataItem.tallas.map(talla => (
                                                <button className={selectedTalla.name === talla.name ? "select" : ""} onClick={() => { 
                                                    setSelectedTalla(talla)
                                                }}>{talla.name}</button>
                                            ))
                                        }
                                    </div>
                                </div>
                                {
                                    selectedTalla.count > 0 ?
                                        <>
                                            <p>Cantidad:</p>
                                            <input type="number" value={amount} min="0" max={selectedTalla.count} onChange={(e) => {
                                                setAmount(parseInt(e.target.value));
                                                setSubtotal(e.target.value * dataItem.price)
                                            }} />
                                            <p className="subtotal">Subtotal: <span className="bold">${subtotal}</span></p>
                                            <button className="addCart" onClick={addToCart}>Agregar al carrito</button>
                                        </>
                                        :
                                        <>

                                        </>
                                }

                            </>
                            :
                            <>
                                <p>No hay disponibles.</p>
                            </>
                        }

                    </div>
                </div>
            </div>
        </main>
    )
}

export default ProductoPage