import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../styles/productoCarrusel.css';

export class ProductoCarrusel extends Component {
  render() {
    const item = this.props.producto;
    return (
      <Link to={`/producto/${item.idItem}`}>
        <div className="producto-carrusel">
            <img src={item.img} alt={item.title} />
            <p className="titleAnime-carrusel">{item.anime}</p>
            <p className="titleProducto-carrusel">{item.title}</p>
            <p className="precioProducto-carrusel">${item.price}</p>
            <p className="stockProducto-carrusel">{item.stock ? "En stock" : "Sin stock"}</p>
        </div>
      </Link>
    )
  }
}

export default ProductoCarrusel