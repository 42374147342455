import React from 'react'
import styles from "../styles/login.module.css";
import { useForm } from "react-hook-form"
import { signInUser, signUpUser } from '../api/auth/api';
import Swal from 'sweetalert2'
import Cookies from "universal-cookie"
import { useContext } from 'react';
import { UserContext } from '../context/userContext';
import jwt from "jwt-decode"
import { Navigate } from 'react-router-dom';


const LoginPage = () => {

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        mode: "onBlur",
    });

    const {
        register: register2,
        formState: { errors: errors2 },
        handleSubmit: handleSubmit2,
    } = useForm({
        mode: "onBlur",
    });

    const cookies = new Cookies();
    const [userData, setUserData] = useContext(UserContext);

    const onSubmitLogin = (data) => {
        signInUser(data).then(res => {

            const decoded = jwt(res.data.token)

            setUserData(res.data.user)
            cookies.set("jwt_auth", res.data.token, {
                expires: new Date(decoded.exp * 1000)
            })
            window.location = "/"

            console.log(res.data);


        }).catch(err => {
            console.log(err.response.data.message);
            Swal.fire({
                title: 'Error!',
                text: err.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
        })
    }

    const onSubmitRegister = (data) => {
        signUpUser(data).then(res => {

            const decoded = jwt(res.data.token)

            setUserData(res.data.user)
            cookies.set("jwt_auth", res.data.token, {
                expires: new Date(decoded.exp * 1000)
            })
            window.location = "/"

            console.log(res.data);


        }).catch(err => {
            console.log(err.response.data.message);
            Swal.fire({
                title: 'Error!',
                text: err.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
        })
        console.log(data)
    }


    return (
        <main>
            {userData && <Navigate to="/" replace={true} />}
            
            <div className="containerMain">
                <div className={styles.main}>
                    <input type="checkbox" id="chk" aria-hidden="true" className={styles.chk} />

                    <div className={styles.signup}>
                        <form onSubmit={handleSubmit2(onSubmitLogin)}>
                            <label for="chk" aria-hidden="true" className={styles.label}>Iniciar sesión</label>
                            <p className={styles.description}>Ingresa las credenciales de tu cuenta para ingresar</p>

                            <div className={styles.inputContainer}>
                                <input type="email" name="email" placeholder="Correo" required="" className={styles.input}
                                    {...register2("email", { required: true })} />

                                {errors2.email && <p className={styles.error}>Ingresa un correo</p>}
                            </div>

                            <div className={styles.inputContainer}>
                                <input type="password" name="pswd" placeholder="Contraseña" required="" className={styles.input}
                                    {...register2("password", { required: true })} />

                                {errors2.password && <p className={styles.error}>Ingresa una contraseña</p>}
                            </div>


                            <button className={styles.btn} type="submit">Iniciar Sesión</button>
                        </form>
                    </div>

                    <div class={styles.login}>
                        <form onSubmit={handleSubmit(onSubmitRegister)}>
                            <label for="chk" aria-hidden="true" className={styles.label}>Registro</label>

                            <div className={styles.inputContainer}>
                                <input type="email" name="email" placeholder="Correo" required=""
                                    className={styles.input}
                                    {...register("email", { required: true })} />
                                {errors.email && <p className={styles.error}>Ingresa un correo</p>}
                            </div>

                            <div className={styles.inputContainer}>
                                <input type="text" name="txt" placeholder="Nombre de Usuario" required="" className={styles.input}
                                    {...register("username", { required: true })} />
                                {errors.username && <p className={styles.error}>Ingresa un nombre de usuario</p>}
                            </div>

                            <div className={styles.inputContainer}>
                                <input type="password" name="pswd" placeholder="Contraseña" required="" className={styles.input}
                                    {...register("password", { required: true })} />
                                {errors.password && <p className={styles.error}>Ingresa una contraseña</p>}
                            </div>


                            <button className={styles.btn} type="submit">Registrarme</button>
                        </form>
                    </div>
                </div>
            </div>



        </main>
    )
}

export default LoginPage